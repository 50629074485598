import React from 'react';
import TestimonialCarousel from './TestimonialCarousel.jsx';
import { theme, websiteColors } from "../../themes/Theme.js";
const HomeFeedback = () => {
  return <div className="px-sm-5 font-figtree px-3 d-flex flex-column flex-lg-row flex-wrap justify-content-center gap-3 align-items-center" data-sentry-component="HomeFeedback" data-sentry-source-file="HomeFeedback.jsx">
      {/* <img src="/img/kh-b.svg" alt="Logo" className="ticket-logo d-lg-none d-block" /> */}

      <div className="testimonial-container py-lg-5">
        <p className="section-heading mb-2 text-xl-start text-center">WHAT USERS SAY ABOUT KONFHUB?</p>
        <h1 className="main-heading text-lg-start text-center mt-4">
          Don’t Take Our Word For It.
          <br />
          Trust Our Users
        </h1>
      </div>

      <div className="c-height py-lg-5">
        <div className="ticket-container d-flex flex-column d-lg-flex mx-auto px-1 p-lg-4">
          <div className="d-flex flex-column flex-lg-row align-items-center">
            {/* <div className="ticket-image-container">
              <img src="/img/kh-b.svg" alt="Logo" className="ticket-logo d-lg-block d-none" />
             </div> */}

            <div className="ticket-text-container pb-2 py-sm-3">
              <TestimonialCarousel data-sentry-element="TestimonialCarousel" data-sentry-source-file="HomeFeedback.jsx" />
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .ticket-container {
          background-color: white;
          max-width: 750px;
          margin: auto;
          overflow: hidden;
          //background: url(/img/vector.webp) no-repeat;
background: url('https://d2nnrpx7gk2my2.cloudfront.net/files/672b44483d422f0008e37e33/Vector(1).svg') no-repeat;
          background-size: contain;
          height: auto;
          display: flex;
          align-items: center;
          position: relative;
          min-height: 330px;
          padding: 20px;
        }

        .c-height {
          height: auto;
        }

        .ticket-image-container {
          position: absolute;
          left: 40px;
          top: 50%;
          transform: translateY(-50%);
        }

        .ticket-logo {
          max-width: 140px;
          border-radius: 50%;
        }

        .ticket-text-container {
         // padding-left: 200px;
         // padding-right: 20px;
          width: 100%;
          max-width: 750px;
        }

        .testimonial-author {
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          color: ${websiteColors[theme].textParagraph};
        }

        .testimonial-author span {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: ${websiteColors[theme].textParagraph};
        }

        .testimonial-text {
          font-size: 16px;
          font-weight: 500;
          line-height: 27.2px;
          color: ${websiteColors[theme].textParagraph};
        }

        .testimonial-container {
          max-width: 380px;
          padding: 20px 0;
          margin: 0 auto;
        }

        .section-heading {
          font-size: 14px;
          font-weight: 600;
          color: #001a47;
          letter-spacing: 0.1em;
          margin-bottom: 10px;
        }

        .main-heading {
          font-size: 48px;
          font-weight: 600;
          line-height: 57.6px;
          color: #001a47;
          margin: 0;
        }
         @media (min-width: 1440px) {
            .testimonial-container{
              margin-right: auto;
              margin-left: 0;
              max-width: 100%;
            }

         }
         @media (max-width: 1024px) {
              .testimonial-container {
        max-width: 629px !important;
        text-align: center !important;
    }
    .main-heading{
        text-align:center !important;
    }
}
        @media (max-width: 990px) {
          .ticket-container {
            background: none;
          }

          .ticket-text-container {
            padding-left: 10px;
            padding-right: 10px;
            max-width: 750px;
          }

          .testimonial-container {
            max-width: 700px;
            padding: 20px 0;
            margin: 0 auto;
            text-align: center;
          }
        }

        @media (max-width: 768px) {
          .ticket-container {
            flex-direction: column;
            height: auto;
            align-items: flex-start;
            background: none;
          }

          .ticket-image-container {
            position: relative;
            left: 0;
            transform: none;
            margin-bottom: 20px;
          }

          .ticket-logo {
            max-width: 100px;
          }

          .ticket-text-container {
            padding-left: 20px;
            padding-right: 20px;
            width: 100%;
          }
        }

        @media (max-width: 1200px) {
          .testimonial-container {
            max-width: 630px;
            text-align: center !important;
          }
           .main-heading {
              text-align: center !important;
           }
           .section-heading{
              text-align: center !important;
           }
        }

        @media (max-width: 576px) {
          .main-heading {
            font-size: 32px;
            line-height: 48px;
            font-weight: 600;
          }

          .testimonial-author {
            font-size: 14px;
          }

          .ticket-text-container {
            padding-left: 5px;
            padding-right: 5px;
            width: 100%;
          }
        }
      `}</style>
    </div>;
};
export default HomeFeedback;