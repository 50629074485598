import React, { useEffect, useState } from "react";
import { theme, websiteColors } from "../../themes/Theme.js";
import TaxForm from "./TaxForm.jsx";
import Ticket from "../../pages/Checkout/RegistrationComponents/SubComponents/CartBox/Settlements/calculations";
import { useRouter } from "next/router.js";
import { GOLD_PLAN, LITE_PLAN, SILVER_PLAN } from "../../config/constants";
const rootClassName = "ticket_calculator";
const EventPricingCalculator = () => {
  const [currentTicketValue, setCurrentTicketValue] = useState(100);
  const [currentParticipants, setParticipants] = useState(1);
  const updateSliderBackground = (value, min, max, id) => {
    const percentage = (value - min) / (max - min) * 100;
    const slider = document.getElementById(id);
    if (slider) {
      slider.style.setProperty("--value", `${percentage}%`);
      slider.style.background = `linear-gradient(to right, #002d72 ${percentage}%, #d1e9fe ${percentage}%)`;
    }
  };

  // useEffect to ensure initial background fill is correct
  useEffect(() => {
    updateSliderBackground(currentTicketValue, 0, 100000, "ticketPrice");
    updateSliderBackground(currentParticipants, 0, 100000, "attendees");
  }, [currentTicketValue, currentParticipants]);
  const handleSliderChange = (e, isTicket = true) => {
    const value = Number(e.target.value);
    if (isTicket) {
      setCurrentTicketValue(value);
      updateSliderBackground(value, 0, 100000, "ticketPrice");
    } else {
      setParticipants(value);
      updateSliderBackground(value, 0, 100000, "attendees");
    }
  };
  const router = useRouter();
  const [isTaxChecked, setIsTaxChecked] = useState(false);
  const [organiserKonfhubFee, setOrganiserKonfhubFee] = useState(false); //Processing Fee
  const [participantKonfhubFee, setParticipantKonfhubFee] = useState(true); //Processing Fee
  const [organiserTaxCharge, setOrganiserTaxCharge] = useState(false); //tax charges
  const [participantTaxCharge, setParticipantTaxCharge] = useState(false); //tax charges
  const [taxPercentageOnCalculator, setTaxPercentageOnCalculator] = useState(18);
  const [openTotal, setOpenTotal] = useState(false);
  const [openPlatformFee, setOpenPlatformFee] = useState(false);
  const [isNotINR, setIsNotINR] = useState(false);
  const [activeTab, setActiveTab] = useState(SILVER_PLAN);
  const planBasedProcessingFees = {
    Lite: 2,
    // Lite
    Silver: 3.75,
    // Silver
    Gold: 4.25 // Gold
  };
  let originalPrice = currentTicketValue * currentParticipants;
  let isTaxable = participantTaxCharge;
  let isTaxInclusive = participantTaxCharge;
  let taxPercentage = taxPercentageOnCalculator;
  let isPgChargeInclusive = participantKonfhubFee;
  let pgChargesPercentage = planBasedProcessingFees[activeTab];
  let pvt_discount = 0;
  let group_discount = 0;
  let isTaxOnPgInclusive = false;
  let taxPercentageOnPg = 18;
  let basePgPercentage = planBasedProcessingFees[activeTab] / (1 + 18 / 100);
  const ticket = new Ticket(originalPrice, isTaxable, isTaxInclusive, taxPercentage, isPgChargeInclusive, pgChargesPercentage, pvt_discount, group_discount, isTaxOnPgInclusive, taxPercentageOnPg, basePgPercentage);
  const ticketPriceDetails = ticket.calculate();
  const pgFee = ticketPriceDetails.payableAmount * 2 / 100;
  const pgFeeTax = pgFee * 18 / 100;
  const totalPGFee = (Number(pgFee) + Number(pgFeeTax)).toFixed(2);
  const platformFee = (Number(ticketPriceDetails.khFee) - Number(totalPGFee)).toFixed(2);
  const taxHandle = e => {
    setIsTaxChecked(!isTaxChecked);
    if (e.target.checked == true) {
      setParticipantTaxCharge(true);
    } else {
      setParticipantTaxCharge(false);
      setOrganiserTaxCharge(false);
    }
  };
  const handleCurrency = () => {
    setIsNotINR(!isNotINR);
  };
  const changeTab = tab => {
    setActiveTab(tab);
    if (tab === LITE_PLAN) {
      setOpenPlatformFee(false);
    }
  };
  const handleChange = (e, isCurrentTicketValue = true) => {
    const inputValue = e.target.value;
    if (inputValue === "" || parseInt(inputValue, 10) <= 10000000) {
      if (isCurrentTicketValue) {
        setCurrentTicketValue(inputValue);
      } else {
        setParticipants(inputValue);
      }
    }
  };
  return <div className="font-figtree" data-sentry-component="EventPricingCalculator" data-sentry-source-file="EventPricingCalculator.jsx">
      <div className=" px-sm-5 px-3 pb-5  ">
        <div className="text-center text-container">
          <h2 className="save-heading">Save your cost with KonfHub</h2>
          <p className="tax-desc my-5">
          No upfront, set-up or subscription charges. Free events and Paid events incur plan-based KonfHub fees + payment gateway fees and applicable taxes per ticket.
          </p>
        </div>
        <div className="pricing-box mx-auto p-4">
          <div className="plans-container">
            <button className={`plan-button ${activeTab === LITE_PLAN ? "active" : ""}`} onClick={() => changeTab(LITE_PLAN)}>
              Lite Plan
            </button>
            <button className={`plan-button ${activeTab === SILVER_PLAN ? "active" : ""}`} onClick={() => changeTab(SILVER_PLAN)}>
              Silver Plan
            </button>
            <button className={`plan-button ${activeTab === GOLD_PLAN ? "active" : ""}`} onClick={() => changeTab(GOLD_PLAN)}>
              Gold Plan
            </button>
          </div>
          <div className="form-group">
            <div className="d-flex mb-4 justify-content-between align-items-center">
              <label htmlFor="ticketPrice" className="form-label">
                Ticket Price*
              </label>{" "}
              <div className="text-end d-flex align-items-center ">
               <span className="ms-1">₹</span> {" "}
                <input type="number" min="0" max="10000000" value={currentTicketValue} onChange={e => handleChange(e)} style={{
                textAlign: "right"
              }} className="border bg-transparent no-border w-100  border-primary border-0" />
              </div>
            </div>{" "}
            <input type="range" className="form-range" id="ticketPrice" value={currentTicketValue} onChange={e => handleSliderChange(e, true)} min={0} max={100000} minDistance={10} />
          </div>

          <div className="form-group mt-5 mb-5">
            <div className="d-flex mb-4 justify-content-between align-items-center">
              <label htmlFor="attendees" className="form-label">
                Attendees Per Event*
              </label>{" "}
              <div className="text-end d-flex align-items-center">
                <input type="number" min="0" max="10000000" value={currentParticipants} onChange={e => handleChange(e, false)} style={{
                textAlign: "right"
              }} className="border w-100  bg-transparent  border-primary border-0 no-border" />
              </div>
            </div>{" "}
            <input type="range" className="form-range" id="attendees" value={currentParticipants} onChange={e => handleSliderChange(e, false)} min={0} max={100000} />
          </div>
          <TaxForm setTaxPercentageOnCalculator={setTaxPercentageOnCalculator} organiserKonfhubFee={organiserKonfhubFee} participantKonfhubFee={participantKonfhubFee} organiserTaxCharge={organiserTaxCharge} participantTaxCharge={participantTaxCharge} setOrganiserKonfhubFee={setOrganiserKonfhubFee} setParticipantKonfhubFee={setParticipantKonfhubFee} setParticipantTaxCharge={setParticipantTaxCharge} setOrganiserTaxCharge={setOrganiserTaxCharge} setOpenTotal={setOpenTotal} taxHandle={taxHandle} isTaxChecked={isTaxChecked} data-sentry-element="TaxForm" data-sentry-source-file="EventPricingCalculator.jsx" />
          <div className="summary mt-5 py-4 d-flex flex-column gap-3">
            <div className="d-flex justify-content-between align-items-center">
              <p className="left-info-txt d-flex gap-1 align-items-center">
                Total:{" "}
                {isTaxChecked && <span className={`dropdownicon ${openTotal ? "icon-open-wt" : "closed-icon-wt"}`} onClick={() => setOpenTotal(!openTotal)}></span>}
              </p>{" "}
              <p className="right-info-txt">
                {" "}
                {isNotINR ? "$" : "₹"}{" "}
                {Number(ticketPriceDetails.payableAmount).toFixed(2)}
              </p>
            </div>{" "}
            {openTotal && <div className="d-flex justify-content-between align-items-center">
                {openTotal && <p style={{
              width: "100%"
            }} className="hide-prices-text d-flex justify-content-between mb-0 hide-prices ">
                    <span className={` left-info-txt breakdown_subHeading_${rootClassName}`}>
                      GST({taxPercentageOnCalculator}%):
                    </span>
                    <span className="hide-price-text-two right-info-txt">
                      {isNotINR ? "$" : "₹"}{" "}
                      {organiserKonfhubFee && organiserTaxCharge || participantTaxCharge && participantKonfhubFee ? Number(ticketPriceDetails.taxAmount).toFixed(2) : participantTaxCharge && organiserKonfhubFee ? (Number(ticketPriceDetails.taxAmount) + Number(ticketPriceDetails.taxOnPgCharges)).toFixed(2) : 0}
                    </span>
                  </p>}
              </div>}
            <div className="d-flex justify-content-between align-items-center my-2">
              <p className="left-info-txt d-flex gap-1 align-items-center">
                Processing Fee:{" "}
                {activeTab !== LITE_PLAN && <span className={`dropdownicon ${openPlatformFee ? "icon-open-wt" : "closed-icon-wt"}`} onClick={() => setOpenPlatformFee(!openPlatformFee)}></span>}
              </p>{" "}
              <p className="right-info-txt">
                {" "}
                {isNotINR ? "$" : "₹"}{" "}
                {Number(ticketPriceDetails.khFee).toFixed(2)}
                </p>
            </div>{" "}
            {openPlatformFee && <div className="d-flex justify-content-between align-items-center">
                {openPlatformFee && <div style={{
              width: "100%"
            }} className="hide-prices ">
                    <p className="hide-prices-text d-flex justify-content-between mb-2">
                      <span className={` left-info-txt breakdown_subHeading_${rootClassName}`}>
                        KonfHub Fee :
                      </span>
                      <span className="hide-price-text-two right-info-txt">
                        {isNotINR ? "$" : "₹"} {platformFee}
                      </span>
                    </p>
                    <p className="hide-prices-text d-flex justify-content-between mt-2 mb-0">
                      <span className={` left-info-txt breakdown_subHeading_${rootClassName}`}>
                        Payment Gateway Fee :
                      </span>
                      <span className="hide-price-text-two right-info-txt">
                        {isNotINR ? "$" : "₹"} {totalPGFee}
                      </span>
                    </p>
                  </div>}
              </div>}
            <div className="d-flex justify-content-between align-items-center">
              <p className="left-info-txt">Estimated Earnings:</p>{" "}
              <p className="right-info-txt">
                {" "}
                {isNotINR ? "$" : "₹"}{" "}
                {(Number(ticketPriceDetails.payableAmount) - Number(ticketPriceDetails.khFee)).toFixed(2)}
              </p>
            </div>{" "}
            <p style={{
            fontWeight: "500",
            color: "#525252",
            fontSize: "11.25px",
            lineHeight: "20.88px"
          }}>Provided calculations are approx. and assumptions & might vary based on mode of payment</p>
          </div>
          {/* <ul className="p-0">
            <li>
              <small className="text-muted">
                Estimates for illustration only.
              </small>
              <br />
            </li>
            <li>
              <small className="text-muted">
                Fees include 18% GST (KonfHub & Payment Gateway).
              </small>
              <br />
            </li>
            <li>
              <small className="text-muted">
                Payment gateway fees vary (2-3% based on method).
              </small>
            </li>
           </ul> */}
          <div style={{
          fontWeight: "500",
          color: "#525252",
          fontSize: "11.25px",
          lineHeight: "20.88px"
        }} className="text-center">
            <div>KonfHub Fee & Payment Gateway Fee are inclusive of GST in this calculator.</div>
            <div>* Ticket price calculator provides estimated pricing and breakdown for illustrative purposes only. Configurations made are not saved for your event.</div>
            <div>* Payment gateway charges generally range from 2 to 3% based on mode of payment</div>
            <div>* All fees are inclusive of GST at 18%</div>
          </div>
         
        </div>
      </div>

      <style jsx>
        {`
       
          .save-heading {
            color: #001234;
            font-weight: 600;
            font-size: 36px;
          }
          .icon-open-wt {
            background: url("/img/dropdown.svg") no-repeat;
            transform: rotate(-180deg);
          }
          .closed-icon-wt {
            background: url("/img/dropdown.svg") no-repeat;
          }
          .dropdownicon {
            //background: url('/img/dropdown.svg') no-repeat;
            height: 20px;
            width: 20px;
            display: inline-block;
            cursor: pointer;
          }

          .text-container {
            max-width: 950px;
            width: 100%;
            margin: auto;
          }
          small {
            font-size: 11.89px !important;
            font-weight: 500 !important;
            line-height: 16.88px;
            color: #525252;
          }
          .right-info-txt {
            font-size: 20px;
            font-weight: 600 !important;
            line-height: 22px;
            color: #44475b;
          }
          .left-info-txt {
            font-size: 14px;
            font-weight: 500 !important;
            line-height: 20px;
            color: #525252;
          }

          .tax-desc {
            font-weight: 400;
            font-size: 20px;
            color: #475467;
            line-height: 30px;
          }

          .form-range {
            -webkit-appearance: none;
            width: 100%;
            height: 4px;
            background: linear-gradient(
              to right,
              #002d72 0%,
              #002d72 50%,
              #d1e9fe 50%,
              #d1e9fe 100%
            );
            border-radius: 0px;
            outline: none;
            transition: background 0.15s ease-in-out;
          }

          .form-range::-webkit-slider-runnable-track {
            width: 100%;
            height: 4px;
            background: linear-gradient(
              to right,
              #002d72 0%,
              #002d72 50%,
              #d1e9fe 50%,
              #d1e9fe 100%
            );
            border-radius: 5px;
          }

          .form-range::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 28px;
            height: 28px;
            margin-top: -12px;
            border-radius: 50%;
            background: #d1e9fe;
            border: 2px solid white;
            cursor: pointer;
            transition: background 0.15s ease-in-out;
          }

          .form-range::-moz-range-thumb {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: #d1e9fe;
            border: 2px solid white;
            cursor: pointer;
            transition: background 0.15s ease-in-out;
          }

          .form-range::-ms-thumb {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: #d1e9fe;
            border: 2px solid white;
            cursor: pointer;
            transition: background 0.15s ease-in-out;
          }

          .form-range::-webkit-slider-runnable-track {
            background: linear-gradient(
              to right,
              #002d72 0%,
              #002d72 var(--value),
              #d1e9fe var(--value),
              #d1e9fe 100%
            );
          }

          .form-range::-moz-range-track {
            background: linear-gradient(
              to right,
              #002d72 0%,
              #002d72 var(--value),
              #d1e9fe var(--value),
              #d1e9fe 100%
            );
          }

          .form-range::-ms-track {
            background: transparent;
            border-color: transparent;
            color: transparent;
          }

          /* Dynamic range fill - updated dynamically */
          .form-range {
            --value: 80%;
          }

          /* Example custom body and label styles */
          body {
            background-color: #f8f9fa;
          }
          .customSlider {
            /* max width of your slider */
            width: 100%;
            /* Optional: Only If you want to center your slider in the page */
            margin: auto;
          }

          .customSlider-track {
            /* Top value to align your track to the center of your thumb */
            top: 8px;
            /* thickness of the track */
            height: 5px;
            /* default color of your track */
            background: #c4c4c4;
            border-radius: 7px;
          }

          .customSlider-track.customSlider-track-0 {
            /* color of the track before the thumb */
            background: linear-gradient(270deg, #fb5850 51.77%, #572148 83.63%);
          }
          .customSlider-thumb {
            cursor: pointer;
            /*color for the thumb */
            background: #fb5850;
            /* shape of the thumb: circle */
            width: 20px;
            height: 20px;
            border-radius: 100%;
            /* remove default outline when selected */
            outline: none;
          }

          .customSlider-thumb:hover {
            background: #ffffff;
            border: 3px solid #fb5850;
          }

          .customSlider-mark.customSlider-mark-before {
            background-color: linear-gradient(
              270deg,
              #fb5850 51.77%,
              #572148 83.63%
            );
          }

          .customSlider-mark.customSlider-mark-active {
            display: none;
          }

          h2 {
            font-size: 24px;
            color: #002d72;
          }

          p {
            font-size: 14px;
            color: #6c757d;
          }

          .pricing-box {
            background-color: white;
            border: 1px solid #e5e5e5;
            border-radius: 10px;
            width: 100%;
            max-width: 717px;
            padding: 35px !important;
            //box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
          }

          .form-label {
            font-weight: 500;
            font-size: 16px;
            color: #44475b;
            line-height: 19.2px;
          }

          .summary p {
            font-weight: bold;
            margin: 0;
          }

          .text-end {
            font-size: 16px;
            color: ${websiteColors[theme].primaryWebsiteTheme};
            background-color: #e1f0ff;
            border-radius: 4px;
            padding: 5px 5px 5px 5px;
            width: 100px;
            height: 30px;
            font-weight: 600;
          }
          .plans-container {
            display: flex;
            background-color: #e1f0ff;
            width: fit-content;
            padding: 4px;
            border-radius: 4px;
            margin-bottom: 25px;
          }

          .plan-button {
            padding: 4px 10px;
            font-size: 16px;
            font-weight: 500;
            line-height: 22.4px;
            border: 2px solid #e1f0ff; /* Border color */
            background-color: white;
            color: #002e6e; /* Text color */
            cursor: pointer;
            border-radius: 4px;
            margin:0px 1px;
            transition: background-color 0.3s ease;
          }
          button:focus{
            box-shadow:0 0 0 !important;
          }
          input[type="number"]::-webkit-outer-spin-button,
        input[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        /* Remove the border for the input field */
        .no-border {
            border: none;
            outline: none; /* Also removes the focus outline */
            background-color: #e8f0fe; /* Optional: matching background color for a clean look */
            padding: 5px; /* Optional: adjust padding for better spacing */
        }

        /* If you want to keep the focus outline visible when the input is focused */
        .no-border:focus {
            outline: none;
        }


        /* For Firefox */
        input[type="number"] {
            -moz-appearance: textfield;
        }

          .plan-button.active {
            background-color: #002e6e; /* Selected button background color */
            color: white; /* Text color for the selected button */
          }

          .plan-button:hover {
            //background-color: #e1f0ff; /* Light hover effect for non-selected buttons */
          }

          small {
            font-size: 12px;
          }
          .text-muted {
            color: #525252 !important;
            font-size: 11.25px !important;
          }
          @media (max-width: 576px) {
            .pricing-box {
              padding: 24px 10px !important;
            }
         

            ul {
              padding-left: 16px !important;
            }
            .save-heading {
              font-size: 30px !important;
            }
            .right-info-txt {
              font-size: 18px !important;
            }
          }
          @media (max-width: 350px) {
            .pricing-box {
              padding: 20px 10px !important;
            }
          }
        `}
      </style>
    </div>;
};
export default EventPricingCalculator;