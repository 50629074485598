import React, { useState } from "react";
import revampData from '../../data/revampData.json';
function PaymenMethod() {
  const [isHovered, setIsHovered] = useState(false);
  const handleHover = hoverState => {
    setIsHovered(hoverState);
  };
  const PaymenMethod = revampData.data.listTapPaymentOptions.data[0].paymentMethod;
  return <>
    <div className="Wrapper" onMouseEnter={() => handleHover(true)} onMouseLeave={() => handleHover(false)}>
      <div className="Marquee">
        <li className="MarqueeGroup d-flex flex-row">
          {Object.keys(PaymenMethod).map((key, index) => {
            const country = PaymenMethod[key];
            if (country) {
              // Check if country is not null
              return <ul className="px-3 d-flex flex-column text-center card-padding" key={index}>
                  <img className="country-flag" src={country.imageUrl} alt={country.name} />
                  <p className="mt-2 text-uppercase country-name">{country.name}</p>
                </ul>;
            }
            return null;
          })}
        </li>
      </div>
    </div>
    <style>
      {`
        .country-name {
          font-family: "Nunito";
          font-weight: 400;
          font-size: 10px;
          line-height: 14px;
        }

        .Wrapper {
          width: 100%;
          height: fit-content;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }

        .Text {
          font-size: 35px;
          font-weight: 500;
          margin-bottom: 10px;
          color: #02203c;
        }

        .Note {
          font-size: 18px;
          font-weight: 200;
          margin-bottom: 40px;
          color: #7c8e9a;
        }

        .Marquee {
          display: flex;
          width: 100%;
          overflow: hidden;
          user-select: none;
        }

        @keyframes scrollX {
          from {
            transform: translateX(0);
          }
          to {
            transform: translateX(-100%);
          }
        }

        .common {
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: space-around;
          white-space: nowrap;
          width: 100%;
          ${isHovered ? "animation-play-state: paused;" : ""}
        }

        .MarqueeGroup {
          flex-shrink: 0;
          display: flex;
          align-items: center;
          white-space: nowrap;
          animation: scrollX 30s linear infinite; /* Changed to 60s */
          ${isHovered ? "animation-play-state: paused;" : ""}
        }

        .country-flag {
          height: 60px;
        }

        @media only screen and (max-width: 480px) {
          .country-flag {
            height: 55px;
            width: 80px;
          }
          .card-padding {
            padding-right: .5rem !important;
            padding-left: .5rem !important;
          }
        }
      `}
    </style>
  </>;
}
export default PaymenMethod;