import React from 'react';
import revampData from '../../data/revampData.json';
import { theme, websiteColors } from "../../themes/Theme.js";
import { hostEventUrl } from '../../config/config.js';
const HomeBottomCta = () => {
  const callToActionData = revampData.data.listCallToActionButtons.data[0]; // Fetching the first object directly as per the JSON structure
  return <div className="event-cta font-figtree text-center mb-5 py-4 pt-2" data-sentry-component="HomeBottomCta" data-sentry-source-file="HomeBottomCta.jsx">
      {callToActionData && <h2 className="cta-title">
          {callToActionData?.ctaTitle?.title}{" "}
          <span className="highlight-text">
            {callToActionData?.ctaTitle?.highlightText}
          </span>{" "}
          {callToActionData?.ctaTitle?.description}
        </h2>}

      <div className="cta-buttons d-flex justify-content-center flex-wrap mt-4 gap-3">
        {callToActionData?.buttons?.map((button, index) => <a key={index} target="_blank" href={button.text === "Host an Event" ? `${hostEventUrl}/create/event` : button.url} className={`btn ${index === 0 ? 'host-btn' : 'demo-btn'}`} rel="noopener noreferrer">
            {button.text}
          </a>)}
      </div>

      <style jsx>{`
        .host-btn {
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
          font-weight: 700;
          background-color: ${websiteColors[theme].primaryWebsiteTheme};
          color: #fff;
          padding: 10px 40px;
          border-radius: 2px;
        }
        .demo-btn {
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
          font-weight: 700;
          color: ${websiteColors[theme].primaryWebsiteTheme};
          padding: 10px 40px;
          border-radius: 2px;
          border: 1px solid ${websiteColors[theme].primaryWebsiteTheme};
          background-color: #fff;
        }

        .cta-title {
          font-size: 36px;
          font-weight: 700;
          line-height: 44px;
        }
        .highlight-text {
          color: ${websiteColors[theme].primaryWebsiteTheme};
        }
        @media screen and (max-width: 992px) {
          .cta-title {
            font-size: 32px;
            font-weight: 700;
            line-height: 44px;
          }
        }
        @media screen and (max-width: 576px) {
          .demo-btn,
          .host-btn {
            width: 90%;
          }
        }
      `}</style>
    </div>;
};
export default HomeBottomCta;