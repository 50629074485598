import React from "react";

const LocationCountryConfig = ({
  heading,
  items,
  itemKeyName,
  itemUrlKeyName,
}) => {
  return (
    <div className="my-2  mb-4 font-figtree">
      <h4 className="locationCategory-heading">{heading}</h4>
      <ul className="locationCategory-ul">
        {items.map((item, index) => (
          <li key={index} className="locationCategory-item">
            <a
              href={item[itemUrlKeyName]}
              target="_blank"
              rel="noopener noreferrer"
              className="locationCategory-link"
            >
              {item[itemKeyName]}
            </a>
            {index < items.length - 1 && " •"}
          </li>
        ))}
      </ul>
      <style jsx>
        {`
          .locationCategory-ul {
            list-style: none;
            padding: 0;
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
          }
          .locationCategory-heading {
            color: #ffffff;
            font-size: 12px;
            font-weight: 700;
            line-height: 20px;
            text-transform: uppercase;
          }
          .locationCategory-link {
            color: white;
            textdecoration: none;
          }
          .locationCategory-item {
            color: #d6d6d6;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
          }
        `}
      </style>
    </div>
  );
};

export default LocationCountryConfig;
