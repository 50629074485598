import React, { useEffect } from 'react';
const GartnerReviews = () => {
  useEffect(() => {
    // Check if the script already exists to avoid adding it multiple times
    const existingScript = document.querySelector('script[src="https://www.gartner.com/peer-insights/vendor-portal/public/Widget/js/widget.js"]');
    if (!existingScript) {
      // Create the script element for the widget.js only if it doesn't already exist
      const script = document.createElement('script');
      script.src = 'https://www.gartner.com/peer-insights/vendor-portal/public/Widget/js/widget.js';
      script.async = true;

      // Append the script to the body or a specific container
      document.body.appendChild(script);

      // When the script is loaded, initialize the widget
      script.onload = () => {
        if (window.GartnerPI_Widget) {
          window.GartnerPI_Widget({
            size: 'line',
            theme: 'dark',
            sourcingLink: 'https://gtnr.io/cto2qUmyU',
            widget_id: 'N2FlMzFmMzktZjcxZS00MDhhLTk3ZTQtODYyYzgyN2NjMzJk',
            version: '2',
            container: document.querySelector('#gartner-widget-container')
          });
          const style = document.createElement('style');
          style.innerHTML = `
            [id^="gartner-pi-widget"][data-theme="dark"] {
              background-color: #0C2753 !important;
              width: 100% !important;
              border-radius: 8px !important;
              padding: 5px !important;
            }

            [id^="gartner-pi-widget"][data-theme="dark"] * {
              color: #fff !important;
              font-family: 'Figtree' !important;
            }
            [id^=gartner-pi-widget][data-size=line] .gartner-pi-as-of-date{
                font-weight:400 !important;
                font-size:8px !important;
            }
            #gartner-widget-container iframe {
              width: 100% !important;
              border-radius: 8px !important;
              border: none;
            }
            @media (max-width: 576px){
              [id^="gartner-pi-widget"][data-theme="dark"] {
              text-align:center;
            }
               [id^=gartner-pi-widget][data-size=line] .gartner-pi-translate{
                transform: translateX(0px) !important;
                margin-top: 3px;
               }
            
               [id^=gartner-pi-widget][data-size=line] .gartner-pi-reviews-link {
                    margin:2px 0px 2px 0px;
                }
            }
          `;
          document.head.appendChild(style);
        }
      };
    } else {
      // Initialize the widget if the script already exists but only if it's not already initialized
      if (window.GartnerPI_Widget) {
        const container = document.querySelector('#gartner-widget-container');
        if (container && !container.hasChildNodes()) {
          window.GartnerPI_Widget({
            size: 'line',
            theme: 'dark',
            sourcingLink: 'https://gtnr.io/cto2qUmyU',
            widget_id: 'N2FlMzFmMzktZjcxZS00MDhhLTk3ZTQtODYyYzgyN2NjMzJk',
            version: '2',
            container: container
          });
        }
      }
    }
  }, []);
  return <div className='gartner-container font-figtree m-auto' data-sentry-component="GartnerReviews" data-sentry-source-file="GartnerReviews.jsx">
      <div id="gartner-widget-container"></div>

      <style jsx>
        {`
          .gartner-container {
            max-width: 522px;
            width: 100%;
            border: 0.67px solid #002E6E;
            border-radius: 10px;
            padding: 6.68px;
          }

          .gartner-container, * {
            font-family: 'Figtree' !important;
          }

          [id^="gartner-pi-widget"] * {
            color: #002856 !important;
            font-weight: 300 !important;
            font-family: 'Figtree' !important;
          }

          [id^='gartner-pi-widget'][data-theme='dark'] {
            background-color: #0C2753 !important;
            width: 100% !important;
            border-radius: 8px !important;
            padding: 5px !important;
          }

          [id^='gartner-pi-widget'][data-size='line'] {
            width: 100% !important;
          }

          #gartner-pi-widget-N2FlMzFmMzktZjcxZS00MDhhLTk3ZTQtODYyYzgyN2NjMzJk {
            background-color: #0C2753 !important;
            width: 100% !important;
            border-radius: 8px !important;
            padding: 5px !important;
          }

          #gartner-widget-container iframe {
            width: 100% !important;
            border-radius: 8px !important;
            border: none;
            padding: 0;
          }
          
         @media (max-width: 576px){
          .gartner-widget-container {
             //width: 340px;
             text-align:center;
          }
     }
        `}
      </style>
    </div>;
};
export default GartnerReviews;