import React, { useState, useEffect } from 'react';
import revampData from '../../data/revampData.json';
import { theme, websiteColors } from "../../themes/Theme.js";

const MobHighlights = () => {
  const categories = revampData.data.listEventCategoriesCarousel.data;

  const reorderByOrderNo = (data) => {
    return data.sort((a, b) => parseInt(a.orderNo) - parseInt(b.orderNo));
  };

  const reorderedData = reorderByOrderNo(categories);

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % reorderedData.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [reorderedData.length]);


  return (
    <div className=" font-figtree pt-1 px-3 mob-container-usecase ">
      <div>
        <div className="carousel-container position-relative">
          {reorderedData.map((category, index) => (
            <div
              key={category.id}
              className={`carousel-item-mob ${index === activeIndex ? 'active' : ''
                }`}
              style={{ display: index === activeIndex ? 'block' : 'none' }}
            >
              <div className="row category-card p-0 m-0">
                <div
                  className={`col-lg-6 category-txt-container d-flex align-items-center order-1 order-lg-${index % 2 === 0 ? '1' : '2'
                    }`}
                >
                  <div className="text-sm-center">
                    <h3 className="fw-bold category-heading">
                      {category.highlight.title}
                    </h3>
                    <p className="category-desc">{category.highlight.subTitle}</p>
                  </div>
                </div>

                <div
                  className={`col-lg-6 order-2 img-h-container order-lg-${index % 2 === 0 ? '2' : '1'
                    }`}
                  style={{
                    maxWidth: '500px',
                    maxHeight: '390px',
                    margin: 'auto',
                  }}
                >
                  <img
                    src={category.highlight.highlightImage}
                    alt={category.highlight.title}
                    className="img-fluid img-fluid-mob"
                  />
                </div>
              </div>
            </div>
          ))}
        </div >
      </div >
      <div className="progress-bar-container d-flex justify-content-center mt-3">
        {reorderedData.map((_, index) => (
          <div
            key={index}
            className={`progress-bar-item ${index === activeIndex ? "active" : ""
              }`}
            onClick={() => setActiveIndex(index)}
          ></div>
        ))}
      </div>
      <style jsx>{`
        .carousel-container {
          position: relative;
        }
        .carousel-item-mob {
          transition: opacity 0.5s ease-in-out;
        }
        .carousel-control {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background: none;
          border: none;
          font-size: 2rem;
          cursor: pointer;
          z-index: 1;
        }
        .carousel-control.prev {
          left: 10px;
        }
        .carousel-control.next {
          right: 10px;
        }
          .progress-bar-container {
          display: flex;
          gap: 10px;
          justify-content: center;
          position: relative;
          //top: -50px;
        }

        .progress-bar-item {
          width: 40px;
          height: 4px;
          background-color: #E5E5E5;
          cursor: pointer;
          transition: background-color 0.3s ease;
        }

        .progress-bar-item.active {
          background-color: #002E6E;
        }
        .category-card {
          border: 1px solid #61c0ff;
          border-radius: 15px;
        }
        .img-fluid-mob {
          border-radius: 9.44px;
        }
        .category-desc {
          font-weight: 400;
          font-size: 14px;
          line-height: 23.8px;
          color: ${websiteColors[theme].textSecondary};
        }
        .category-heading {
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          color: #001a47;
        }
        @media screen and (max-width: 992px) {
          .category-txt-container {
            padding: 20px !important;
          }
          .img-h-container {
            max-height: 400px !important;
            padding: 0px 20px 20px !important;
          }
        }
        @media screen and (max-width:350px) {
            .mob-container-usecase{
                padding-left: 3.5rem !important;
            }
        }
      `}</style>
    </div >
  );
};

export default MobHighlights;
