import React from 'react';
const DataPrivacySection = () => {
  return <section className="data-privacy-section font-figtree" data-sentry-component="DataPrivacySection" data-sentry-source-file="DataPrivacySection.jsx">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-9 text-xl-start text-center col-lg-12 p-0">
            <h2 className="section-title">
              Data Privacy is Our Foremost Priority
            </h2>
            <p className="section-description">
              Your events are in safe hands, and so is your data. We are GDPR compliant and give utmost importance to data privacy and security.
            </p>
          </div>
          <div className="col-xl-3 col-lg-12 text-xl-end text-lg-center text-center">
            <img src="/img/gdpr 2.svg" alt="GDPR Compliant" className="gdpr-icon" />
          </div>
        </div>
      </div>

      <style jsx>{`
        .data-privacy-section {
          background-color: #E1F0FF;
          padding: 40px;
        }

        .section-title {
          font-size: 42px;
          font-weight: 600;
          color: #101828;
          line-height: 50.4px;
        }

        .section-description {
          font-size: 20px;
          color: #475467;
          line-height: 24px;
          font-weight: 400;
        }

        .gdpr-icon {
          width: 200px;
          height: 200px;
        }

        @media (max-width: 992px) {
          .data-privacy-section {
            padding: 64px 32px;
          }

          .section-title {
            font-size: 32px;
            text-align: center;
            font-weight: 600;
          }

          .section-description {
            text-align: center;
          }

          .gdpr-icon {
            margin-top: 20px;
          }
        }

        @media (max-width: 576px) {
          .data-privacy-section {
            padding: 64px 32px;
          }

          .gdpr-icon {
            width: 150px;
            height: 150px;
            margin-top: 20px;
          }
        }
      `}</style>
    </section>;
};
export default DataPrivacySection;