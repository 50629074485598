import React from "react";
const SupportedCurrencies = () => {
  return <div data-sentry-component="SupportedCurrencies" data-sentry-source-file="SupportedCurrencies.jsx">
      <div className="container text-center my-5">
      <div className="mb-5">  <h2 className="Stripe-titl">Stripe Connect</h2>
        <p className="text-mute">
          Using{" "}
          <a target="_blank" href="https://stripe.com/in/connect" className="link-primar">
            Stripe Connect
          </a>
          : Easily link your Stripe account to your event to streamline payment
          processing and receive registration fees directly into your bank
          account.
        </p>
        </div>
        <div className="middl-section d-flex  flex-column gap-4">
        <p className="supports-curr-txt">
          Currently KonfHub supports transactions in 23 currencies.
        </p>

        <div className="currencies-box mx-auto p-3">
          <div className="row ">
            <div className="col-md-6 text-start">
            <div className="d-flex flex-column gap-3">
              <p>
                <span className="currency-code">AED</span>{" "}
                <span className="currency-name">(Emirati Dirham)</span>
              </p>
              <p>
                <span className="currency-code">AUD</span>{" "}
                <span className="currency-name">(Australian Dollar)</span>
              </p>
              <p>
                <span className="currency-code">BGN</span>{" "}
                <span className="currency-name">(Bulgarian Lev)</span>
              </p>
              <p>
                <span className="currency-code">BRL</span>{" "}
                <span className="currency-name">(Brazilian Real)</span>
              </p>
              <p>
                <span className="currency-code">CAD</span>{" "}
                <span className="currency-name">(Canadian Dollar)</span>
              </p>
              <p>
                <span className="currency-code">CHF</span>{" "}
                <span className="currency-name">(Swiss Franc)</span>
              </p>
              <p>
                <span className="currency-code">CZK</span>{" "}
                <span className="currency-name">(Czech Koruna)</span>
              </p>
              <p>
                <span className="currency-code">DKK</span>{" "}
                <span className="currency-name">(Danish Krone)</span>
              </p>
              <p>
                <span className="currency-code">EUR</span>{" "}
                <span className="currency-name">(Euro)</span>
              </p>
              <p>
                <span className="currency-code">GBP</span>{" "}
                <span className="currency-name">(British Pound Sterling)</span>
              </p>
              <p>
                <span className="currency-code">HKD</span>{" "}
                <span className="currency-name">(Hong Kong Dollar)</span>
              </p>
              <p>
                <span className="currency-code">HUF</span>{" "}
                <span className="currency-name">(Hungarian Forint)</span>
              </p>
              </div>
            </div>
            <div className="col-md-6 text-start">
            <div className="d-flex flex-column gap-3">

              <p>
                <span className="currency-code">MYR</span>{" "}
                <span className="currency-name">(Malaysian Ringgit)</span>
              </p>
              <p>
                <span className="currency-code">MXN</span>{" "}
                <span className="currency-name">(Mexican Peso)</span>
              </p>
              <p>
                <span className="currency-code">NOK</span>{" "}
                <span className="currency-name">(Norwegian Krone)</span>
              </p>
              <p>
                <span className="currency-code">NZD</span>{" "}
                <span className="currency-name">(New Zealand Dollar)</span>
              </p>
              <p>
                <span className="currency-code">PHP</span>{" "}
                <span className="currency-name">(Philippine Peso)</span>
              </p>
              <p>
                <span className="currency-code">PLN</span>{" "}
                <span className="currency-name">(Polish złoty)</span>
              </p>
              <p>
                <span className="currency-code">RON</span>{" "}
                <span className="currency-name">(Romanian Leu)</span>
              </p>
              <p>
                <span className="currency-code">SEK</span>{" "}
                <span className="currency-name">(Swedish Krona)</span>
              </p>
              <p>
                <span className="currency-code">SGD</span>{" "}
                <span className="currency-name">(Singapore Dollar)</span>
              </p>
              <p>
                <span className="currency-code">THB</span>{" "}
                <span className="currency-name">(Thai Baht)</span>
              </p>
              <p>
                <span className="currency-code">USD</span>{" "}
                <span className="currency-name">(U.S. Dollar)</span>
              </p>
            </div>
            </div>
          </div>
        </div>

      </div>

        <p className="text-mute mt-4">
          Stripe Connect is supported in 45+ countries in the world. Check the
          list of countries{" "}
          <a target="_blank" href="https://stripe.com/in/global" className="link-primar">
            here
          </a>
          .
        </p>
      </div>
      <style jsx>
        {`
          .container {
            max-width: 850px;
          }
          .Stripe-titl{
            color:#101828;
            font-size:30px;
            font-weight:600;
            line-height:38px;
          }
          .text-mute ,.supports-curr-txt{
            color:#475467;
            font-size:18px;
            font-weight:400;
            line-height:28px;
          }
          .middl-section{
          
          }
          .link-primar{
            text-decoration:none;
            color:#007BFF;
          }

          .currencies-box {
            border: 1px solid #E5E5E5;
            border-radius: 12px;
            max-width:600px;
            width:100%;
          }
      

          .currency-code {
            color: #002e6e;
            font-weight: 500;
            font-size:22px;
            display: inline-block;
            margin-right: 8px;
          }

          .currency-name {
            color: #475467;
            font-weight: 400;
            font-size:18px;
          }
        `}
      </style>
    </div>;
};
export default SupportedCurrencies;