import React from 'react';
import Head from "next/head";
import { NextSeo } from "next-seo";
import { seoFunct } from "../../config/next-seo.config.js";
import { withRouter } from "next/router";
import HomeNav from './HomeNav.jsx';
import HeroSection from './HeroSection.jsx';
import HomePartners from './HomePartners.jsx';
import HomeStatistics from './HomeStatistics.jsx';
import HomeEventTicketingIntro from './HomeEventTicketingIntro.jsx';
import HomeEventBrand from './HomeEventBrand.jsx';
import HomeEventManagementIntro from './HomeEventManagementIntro.jsx';
import HomeCategoriesHighlight from './HomeCategoriesHighlight.jsx';
import HomePricingPlans from './HomePricingPlans.jsx';
import HomeFooter from './HomeFooter.jsx';
import HomeFeedback from './HomeFeedback.jsx';
import HeroFeatureTabs from './HeroFeatureTabs.jsx';
import HeroFeatureAccordion from './HeroFeatureAccordion.jsx';
import HomeBottomCta from './HomeBottomCta.jsx';
import DataPrivacySection from './DataPrivacySection.jsx';
const Home = () => {
  return <div className="main-container font-figtree" data-sentry-component="Home" data-sentry-source-file="Home.jsx">
      <NextSeo {...seoFunct("homeSeo")} data-sentry-element="NextSeo" data-sentry-source-file="Home.jsx" />
      <HomeNav isBgNavyBlue={false} isHomePage={true} data-sentry-element="HomeNav" data-sentry-source-file="Home.jsx" />
      <HeroSection data-sentry-element="HeroSection" data-sentry-source-file="Home.jsx" />
      <HomePartners data-sentry-element="HomePartners" data-sentry-source-file="Home.jsx" />
      <HomeStatistics data-sentry-element="HomeStatistics" data-sentry-source-file="Home.jsx" />
      <HeroFeatureTabs data-sentry-element="HeroFeatureTabs" data-sentry-source-file="Home.jsx" />
      <HeroFeatureAccordion data-sentry-element="HeroFeatureAccordion" data-sentry-source-file="Home.jsx" />
      <HomeEventTicketingIntro data-sentry-element="HomeEventTicketingIntro" data-sentry-source-file="Home.jsx" />
      <HomeEventBrand data-sentry-element="HomeEventBrand" data-sentry-source-file="Home.jsx" />
      <HomeEventManagementIntro data-sentry-element="HomeEventManagementIntro" data-sentry-source-file="Home.jsx" />
      <HomeCategoriesHighlight data-sentry-element="HomeCategoriesHighlight" data-sentry-source-file="Home.jsx" />
      <HomeFeedback data-sentry-element="HomeFeedback" data-sentry-source-file="Home.jsx" />
      <HomePricingPlans ishomePage={'ishomePage'} data-sentry-element="HomePricingPlans" data-sentry-source-file="Home.jsx" />
      <HomeBottomCta data-sentry-element="HomeBottomCta" data-sentry-source-file="Home.jsx" />
      <DataPrivacySection data-sentry-element="DataPrivacySection" data-sentry-source-file="Home.jsx" />
      <HomeFooter data-sentry-element="HomeFooter" data-sentry-source-file="Home.jsx" />
      <style jsx>{`
        .main-container {
          width: 100%;
        }
      `}</style>
    </div>;
};
export default withRouter(Home);