import React, { useState, useEffect } from "react";
import revampData from "../../data/revampData.json";
const SLIDE_CLASSES = {
  ACTIVE: "active",
  PREV: "prev",
  NEXT: "next",
  HIDDEN: "hidden"
};
const HomeEventBrand = () => {
  const customizations = revampData.data.listEventBrandCustomizers.data;
  const [currentIndex, setCurrentIndex] = useState(0);
  const handlePrev = () => {
    setCurrentIndex(prev => prev === 0 ? customizations.length - 1 : prev - 1);
  };
  const handleNext = () => {
    setCurrentIndex(prev => prev === customizations.length - 1 ? 0 : prev + 1);
  };

  // Auto-scroll logic
  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 3000);
    return () => clearInterval(interval);
  }, [currentIndex]);
  return <div className="carousel-container mb-3" data-sentry-component="HomeEventBrand" data-sentry-source-file="HomeEventBrand.jsx">
      <div className="carousel-wrapper">
        {customizations.map((item, index) => {
        const isActive = index === currentIndex;
        const isPrev = index === (currentIndex - 1 + customizations.length) % customizations.length;
        const isNext = index === (currentIndex + 1) % customizations.length;
        return <div key={item.id} className={`carousel-slide ${isActive ? SLIDE_CLASSES.ACTIVE : isPrev ? SLIDE_CLASSES.PREV : isNext ? SLIDE_CLASSES.NEXT : SLIDE_CLASSES.HIDDEN}`}>
              <img src={item.features.featureImage} alt={item.features.title} className="carousel-image" onClick={() => {
            if (isPrev) {
              handlePrev();
            } else if (isNext) {
              handleNext();
            }
          }} />
              {isActive && <div className="carousel-caption">{item.features.title}</div>}
            </div>;
      })}
      </div>

      <div className="progress-bar-container">
        {customizations.map((_, index) => <div key={index} className={`progress-bar-item ${index === currentIndex ? SLIDE_CLASSES.ACTIVE : ""}`} onClick={() => setCurrentIndex(index)} // Set currentIndex to the clicked index
      ></div>)}
      </div>

      <style jsx>{`
        .carousel-container {
          position: relative;
          max-width: 800px;
          margin: auto;
          overflow: hidden;
        }

        .carousel-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          height: 400px;
        }

        .carousel-slide {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
          opacity: 0;
          width: 25%;
        }

        .carousel-slide img {
          width: 100%;
          border-radius: 20px;
          height: 330px;
          object-fit: cover;
          cursor: pointer;
        }

        .carousel-slide.active {
          transform: translate(-50%, -50%) scale(1.1);
          left: 50%;
          width: 55%;
          opacity: 1;
          z-index: 3;
        }

        .carousel-slide.prev {
          transform: translate(-150%, -50%) scale(0.9);
          left: 40%;
          opacity: 0.6;
          z-index: 2;
        }

        .carousel-slide.next {
          transform: translate(50%, -50%) scale(0.9);
          left: 60%;
          opacity: 0.6;
          z-index: 2;
        }

        .carousel-slide.hidden {
          opacity: 0;
          z-index: 1;
        }

        .carousel-caption {
          position: absolute;
          width: 100%;
          padding: 0px 15px;
          max-width: 400px;
          margin: auto;
          left: 50%;
          right: auto;
          text-align: center;
          transform: translateX(-50%);
          font-size: 32px;
          font-weight: 600;
          color: #fff;
          text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
        }

        .progress-bar-container {
          display: flex;
          gap: 10px;
          justify-content: center;
          margin-top: 10px;
        }

        .progress-bar-item {
          width: 50px;
          height: 5px;
          background-color: #e5e5e5;
          cursor: pointer;
          transition: background-color 0.3s ease;
        }

        .progress-bar-item.active {
          background-color: #002e6e;
        }

        @media (max-width: 576px) {
          .carousel-caption {
            font-size: 16px;
          }
          .carousel-wrapper {
            height: 240px;
          }
          .carousel-slide img {
            height: 200px;
          }
          .progress-bar-container {
            margin-top: 15px;
          }
        }
      `}</style>
    </div>;
};
export default HomeEventBrand;