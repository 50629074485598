import React, { useState, useRef } from 'react';
import revampData from '../../data/revampData.json';
import { theme, websiteColors } from "../../themes/Theme.js";
const HomeStatistics = () => {
  const highlights = revampData.data.listHighlightSections.data;
  const [show, setShow] = useState(false);
  const [playing, setPlaying] = useState(false);
  const videoRef = useRef(null);

  // Modal controls
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return <div className="bpx-sm-5 font-figtree px-md-3 px-sm-5 pb-5 px-2" data-sentry-component="HomeStatistics" data-sentry-source-file="HomeStatistics.jsx">
      <div className="row g-3 px-2 m-0">
        {/* Left Column */}
        <div className="col-md-4 col-12 order-md-2 order-2">
          <div className="row g-3">
            <div className="col-12 first-card">
              {/* Connect with events in 50+ countries */}

              <div className="highlights-card d-flex middle-card flex-column h-100">
                <div className="highlights-card-body">
                  <img className="mb-3" style={{
                  height: '35px'
                }} src="https://d2nnrpx7gk2my2.cloudfront.net/files/66e42c3e698d2800087805f2/Frame.svg" alt="" />
                  <h5 className="highlights-card-title" style={{
                  maxWidth: '225px'
                }}>
                    {highlights[0].title}
                  </h5>
                </div>
                {/* <img
                 src="/img/watch-btn.svg"
                 alt="Watch Now"
                 className="watch-btn"
                 //onClick={handleShow} // Trigger the modal on click
                 /> */}
              </div>
            </div>

            <div className="col-12">
              {/* 10,000+ Events Hosted Annually */}
              <div className="card-container text-center h-100">
                <div className="card-body text-start">
                  <img src="/img/events-party.svg" alt="Events" className="star-icon" />
                  <h5 className="card-title highlights-card-title mt-2">{highlights[4].title}</h5>
                  <p className="card-text">{highlights[4].additionalDetails.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Middle Container */}
        <div className="col-md-4 col-12 d-flex order-lg-2 order-1 custom-height">
          <div className="card-container text-center w-100 h-100">
            <div className="card-body text-start ">
              <p className="card-text">{highlights[3].additionalDetails.description}</p>
              <h5 className="card-title highlights-card-title">{highlights[3].title}</h5>
            </div>
            <div className="svg-container">
              {/* <img src="/img/White-Globe.svg" alt="Globe" className="globe-img" /> */}
              <img src={highlights[3].additionalDetails.image} alt="Globe" className="globe-img" />
            </div>

          </div>
        </div>

        {/* Right Column */}
        <div className="col-md-4 col-12 order-md-3 order-3">
          <div className="row g-3">
            <div className="col-12">
              {/* 95% Customer Satisfaction */}
              <div className="card-container text-center h-100">
                <div className="card-body text-start">
                  <img src="/img/magic-wand.svg" alt="Satisfaction" className="star-icon" />
                  <h5 className="card-title highlights-card-title event-solutions mt-2">{highlights[2].title}</h5>
                  <p className="card-text">{highlights[2].additionalDetails.description}</p>
                </div>
              </div>
            </div>

            <div className="col-12">
              {/* Event Solutions, Tailored to You */}
              <div className="text-center gear-container card-container h-100">
                <div className="card-body gear-container-h text-start">
                  <img src="/img/bulb.webp" alt="Solutions" className="mb-3 bulb d-lg-block d-none invisible" />
                  <h5 className="card-title d-lg-block d-none highlights-card-title c-max">
                    {highlights[1].title}
                  </h5>
                  <div className="d-lg-none d-flex event-solutions-cont flex-row-reverse align-items-end justify-content-between w-full">
                    <img src="/img/bulb.webp" alt="Solutions" className="mb-3 bulb invisible" />
                    <h5 className="card-title highlights-card-title c-max">{highlights[1].title}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .highlights-card {
          border: 1px solid #61c0ff;
          border-radius: 8px;
          overflow: hidden;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          transition: all 0.3s ease-in-out;
        }
        .middle-card {
          background: url('/img/event-abstract.webp');
          width: 100% !important;
          background-size: cover;
          background-repeat: no-repeat;
          min-height: 300px;
        }
        .gear-container{
           background: url('https://d2nnrpx7gk2my2.cloudfront.net/files/672ecc488e0c3700081f1f70/gear(1).webp');
          width: 100% !important;
          background-size: cover;
          background-repeat: no-repeat;
        }
        .card-container {
          border: 1px solid #61c0ff;
          border-radius: 10px;
        }
        .star-icon{
          height: 32px;
          width: 32px;
        }

        .highlights-card:hover {
          // transform: translateY(-5px);
          //box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
        }

        .watch-btn {
          margin-top: auto;
          margin-bottom: 20px;
          width: fit-content;
          margin-right: 0;
          margin-left: auto;
        }

        .highlights-card-img-top {
          object-fit: cover;
          width: 100%;
          max-height: 260px;
        }

        .highlights-card-body {
          padding: 20px;
        }

        .highlights-card-title {
          font-size: 1.4rem;
          font-weight: bold;
          margin-bottom: 10px;
        }

        .highlights-card-text {
          font-size: 18px;
          font-weight: 600;
          color: #737373;
          line-height: 21.5px;
        }
        .bulb{
          height: 126px !important;
          width: auto important;
          transform: rotate(348deg) important;
        }
        .globe-img{
          height: 355.8px !important;
          object-fit: cover;
          border-radius: 15px;
        }


        .highlights-card-title {
          font-weight: 600;
          font-size: 32px;
          line-height: 38.4px;
          color: ${websiteColors[theme].primaryWebsiteTheme};
        }
        .first-card {
          min-height: 288px;
        }

        .highlight-bulb {
          height: 215.83px;
          width: 192px;
        }

        .bulb {
          transform: rotate(360deg);
          transform: rotate(352deg);
        }
        .svg-container {
          //  background: linear-gradient(175deg, rgba(255, 255, 255, 0) -19.87%, #61C0FF 42.71%, #61C0FF 78.91%);  position: relative;
            width:100%; /* Adjust based on your needs */
            height: auto; /* Adjust based on your needs */
            //overflow:hidden;
            //border-radius: 8px;
        }

        .globe-img {
          width: 100%;
          height: auto;
          object-fit:contain;
         // mix-blend-mode: luminosity; /* Blending with background using luminosity */
         // transform: scale(1.1); /* Zoom by 10% for ~20px zoom effect */
         // transform-origin: center; /* Ensures the zoom is centered */
         // transition: transform 0.3s ease; /* Optional: smooth zoom effect */
         // border-radius: 8px;
        }

        @media ((min-width: 992px) and (max-width: 1500px)) {
          .c-max {
            max-width: 250px;
          }
          .bulb {
            margin-left: 132px;
            position: relative;
            top: 67px;
            left: 90px;
          }
        }
        @media (min-width: 1500px)  {
          .c-max {
            max-width: 250px;
          }
          .bulb {
            margin-left: 220px;
            position: relative;
            top: 75px;
            left: 90px;

          }
          .feature-tab-container{
            min-height: 590px
          }
        }
        @media ((min-width: 1023px) and (max-width: 1250px)) {
          .bulb {
            left: -9px;
            top: 63px;
          }
        }

        .max-h {
          height: 460px;
        }
         @media (max-width: 1000px) {
         .globe-img{
          height: 351.8px !important;
         }
         }
         @media (max-width: 1000px) {
         .gear-container{
          height: 200px !important;
         }
         }
        @media (max-width: 452px) {
          .c-max {
            max-width: 337px !important;
            width: 251px;
            position: absolute;
            bottom: 10px;
            left: 0;
          }
          .gear-container{
          height: 250px !important;
         }

          .bulb {
            height: 149px;
            transform: rotate(359deg);
            position: absolute;
            bottom: 57px;
          }

          .event-solutions-cont {
            height: 223px;
            position: relative;
            padding-right: 10px !important;
          }
        }
        @media (max-width: 576px) {
            .globe-img{
          height: 353.4px !important;
         
        }
        }
        @media (max-width: 992px) {
          .col-md-4 {
            flex: 0 0 100%;
            max-width: 100%;
          }
          .highlights-card {
            background-size: cover !important;
          }
          .c-max {
            max-width: 275px;
          }
          .middle-card {
            min-height: 300px;
          }
          .custom-height {
            height: 459px;
          }
          .event-solutions {
            max-width: 256px;
          }
          .bulb {
            transform: rotate(359deg) !important;
          }
          
        }
      `}</style>
    </div>;
};
export default HomeStatistics;