import React, { useEffect, useState } from "react";
import { logos } from "../config/logosConfig";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";

const AnimatedLogo = ({ color = "blue", customClass = '', isFromNav = false }) => {
    const [lottieFile, setLottieFile] = useState(null);

    const getLottieForToday = () => {
        const today = new Date();
        for (const logo of logos) {
            const startDate = new Date(logo.startDate);
            const endDate = new Date(logo.endDate);
            if (today >= startDate && today <= endDate && logo.color === color) {
                return logo.lottieFile;
            }
        }
        return null;
    };

    useEffect(() => {
        const currentLottie = getLottieForToday();
        if (currentLottie) {
            setLottieFile(currentLottie);
        }
    }, [color]);

    return (
        <div className={customClass} style={{ display: 'flex', justifyContent: 'flex-start' }}>
            {lottieFile ? (
                <DotLottieReact
                    src={lottieFile}
                    loop
                    autoplay
                    style={{ height: isFromNav ? "60px" : "70px", width: isFromNav ? "180px" : "120px" }}
                />
            ) : (
                <img
                    src={color === "blue" ? 'https://d2nnrpx7gk2my2.cloudfront.net/files/66e42c3e698d2800087805f2/Frame.svg' : 'https://d2nnrpx7gk2my2.cloudfront.net/files/670417abc4d989000847b56a/Frame.svg'}
                    alt="Default Logo"
                    style={{ width: "auto", height: "35px" }}
                />
            )}
        </div>
    );
};

export default AnimatedLogo;