// Event Url (Regarding events)

export const EVENT_URL = "/event/url/"; //(Event details -> /event/url/{event-url} and Event Ticket Details -> /event/url/{event-url}/tickets)
export const EVENT_WAITLIST = "/event/url/waitlist/"; //(EVENT_WAITLIST + {event-url}?booking_id={booking_id})
export const EVENT_VALIDATE = "/event/url/validate"; // Validate if event URL is available\
export const LITE_PLAN = "Lite";
export const SILVER_PLAN = "Silver";
export const GOLD_PLAN = "Gold";
export const ENTITY_TYPES = {
    SPONSORS: 1,
    PARTNERS: 2,
    EXHIBITORS: 3,
};

export const TEMPLATE_TYPES = {
    EventPagePro: 1,
    EventPageTrendy: 2,
    EventPageMinimalistic: 3,
};

export const TICKET_STATUS = {
    CANCELED: "Cancelled",
    WAITLIST: "Approved",
    APPROVED: "Approved",
    APPROVAL_APPROVED: "Approved",
    CONFIRMED: "Confirmed",
};

export const VALID_TICKET_STATUSES = [
    "Confirmed",
];

export const TABS = {
    MANAGE_PROFILE: "manageProfile",
    NOTIFICATION_PREFERENCES: "notificationPreferences",
    ACCOUNT_SETTINGS: "accountSettings"
};

export const SUPPORTED_ENVS = [
    "dev",
    "staging",
    "prod",
]