import React from "react";
import Marquee from "react-fast-marquee";
import revampData from '../../data/revampData.json';
const BadgeCarousel = () => {
  const badges = revampData.data.listRecognitions.data;
  return <div className="carousel-container font-figtree" data-sentry-component="BadgeCarousel" data-sentry-source-file="BadgeCarousel.jsx">
      <Marquee speed={15} gradient={false} pauseOnHover={true} className="badge-marquee" data-sentry-element="Marquee" data-sentry-source-file="BadgeCarousel.jsx">
        {badges.map((badge, index) => badge.images.map((image, imgIndex) => <div key={`${index}-${imgIndex}`} className="badge-item mx-2">
              <img src={image} alt={`Badge ${imgIndex}`} className="badge-img" />
            </div>))}
      </Marquee>

      <style jsx>{`
        .badge-img {
          height: 67.2px;
          width: 60px;
          object-fit: cover;
        }

        .carousel-container {
          width: 100%;
          overflow: hidden;
        }

        .badge-item {
          flex: 0 0 auto;
          margin: 0 10px;
        }
      `}</style>
    </div>;
};
export default BadgeCarousel;