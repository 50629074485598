import React, { useRef, useState } from 'react';
import revampData from '../../data/revampData.json';
import Link from "next/link";
import { useRouter } from "next/router";
import { theme, websiteColors } from "../../themes/Theme.js";
import { hostEventUrl, mediaUrl } from '../../config/config.js';
import GartnerReviews from './GartnerReviews.jsx';
import ReactPlayer from "react-player";
import { Modal } from 'react-bootstrap';
const HeroSection = () => {
  const sections = revampData.data.listCtaSections.data;
  const videoRef = useRef(null);
  const [playing, setPlaying] = useState(true);
  const ratingData = [{
    "id": "capterra",
    "name": "Capterra",
    "rating": 4.8,
    "link": "https://www.capterra.com/p/265126/KonfHub/"
  }, {
    "id": "softwareAdvice",
    "name": "Software Advice",
    "rating": 4.7,
    "link": "https://www.softwareadvice.com/event-management/konfhub-profile/reviews/"
  }, {
    "id": "getApp",
    "name": "GetApp",
    "rating": 4.8,
    "link": "https://www.getapp.com/customer-management-software/a/konfhub/"
  }, {
    "id": "Gartner Peer Insights",
    "name": "Gartner",
    "rating": 4.8,
    "link": "https://www.gartner.com/reviews/market/event-technology-platforms/vendor/konfhub?utm_source=konfhub&utm_medium=referral&utm_campaign=widget&utm_content=N2FlMzFmMzktZjcxZS00MDhhLTk3ZTQtODYyYzgyN2NjMzJk"
  }, {
    "id": "G2",
    "name": "G2",
    "rating": 4.8,
    "link": "https://www.g2.com/products/konfhub/reviews"
  }];
  const style = {
    textDecoration: "none",
    color: "#212529"
  };
  const router = useRouter();
  return <div className="hero-section-container main-container font-figtree mb-4 px-sm-4 px-3 py-4" data-sentry-component="HeroSection" data-sentry-source-file="HeroSection.jsx">
      <div className="d-flex justify-content-center align-items-center pt-4 px-lg-5 px-4">
        <div>
          <section className="hero-section d-flex gap-2 flex-column text-center py-3 py-lg-5">
            <h1 className="display-5 hero-section-title text-tertiary font-xxxl fw-semibold">
              {sections[4].title}
            </h1>
            <p className="lead font-lg hero-section-desc fw-normal text-secondary mb-2">
              {sections[3].title}
            </p>

            <div className="d-flex flex-column flex-sm-row gap-3 justify-content-center align-items-center mt-4">
              <Link target='_blank' href={`${hostEventUrl}/create/event`} data-sentry-element="Link" data-sentry-source-file="HeroSection.jsx"> <button className="host-btn fw-bold font-sm"> {sections[2].title} </button></Link>
              <Link target='_blank' href="https://calendly.com/konfhub/konfhub" data-sentry-element="Link" data-sentry-source-file="HeroSection.jsx"><button className="demo-btn fw-bold font-sm"> {sections[1].title}</button></Link>
            </div>
          </section>

          <div style={{
          maxWidth: "960px"
        }} className="text-center banner-video m-auto mt-3 mb-3 mb-sm-5 ">
            {/* <img
              src={sections[0].image}
              alt={sections[0].description}
              className="cta-img rounded"
              style={{ maxWidth: '950px', width: '100%', height: 'auto' }}
             /> */}
            <div style={{
            maxWidth: '950px',
            width: '100%',
            maxHeight: "505px"
          }}>
              {/* <ReactPlayer
                ref={videoRef}
                url="https://d2nnrpx7gk2my2.cloudfront.net/files/672b1b9c123ee00008663e13/7.8.mp4"
                controls
                playing={true}
                muted={true} // Mutes the video to comply with browser autoplay policies
                width="100%"
                height="100%"
                style={{ maxHeight: "505px",maxWidth: '950px',width: '100%' }}
                onEnded={() => videoRef.current.seekTo(0)}
               /> */}
              <ReactPlayer ref={videoRef} url="https://media.konfhub.com/kh-landing-page.mp4"
            // url={`${mediaUrl}/kh-landing-page.mp4`}
            controls width="100%" height="100%" style={{
              background: "#002E6E",
              border: "8px solid #002E6E",
              boxShadow: "4px 4px 30px rgba(0, 0, 0, 0.25)",
              borderRadius: "8px",
              display: "flex",
              // padding:"8px",
              maxHeight: "560px"
            }} pip autoPlay playing={true} playIcon={<></>} onClickPreview={() => {
              setPlaying(true);
            }} onStart={() => {
              setPlaying(true);
            }} onPause={() => {
              setPlaying(false);
            }} onPlay={e => {
              setPlaying(true);
            }} muted={true} onEnded={() => {
              videoRef.current.seekTo(0); // Loop back to the start when video ends
            }} data-sentry-element="ReactPlayer" data-sentry-source-file="HeroSection.jsx" />
            </div>

          </div>

          <section className="ratings-section m-auto text-center py-4">
            <div style={{
            maxWidth: "790px"
          }} className="d-flex rating-hero-cont flex-wrap gap-3 justify-content-center gap-sm-3 gap-5">
              {ratingData.map(rating => <div style={{
              maxWidth: "260px"
            }} className=" my-3 " key={rating.id}>

                  <div className="rating">
                    <img src="/img/star-count.svg" alt="star count" />
                    <Link target='_blank' style={style} href={rating.link}>
                      <p>
                        {rating.rating}/5{' '}
                        <span className="font-sm-2 fw-semibold text-secondary text-nowrap">
                          {rating.name}
                        </span>
                      </p>
                    </Link>
                  </div>

                </div>)}
            </div>
            <div className='mt-3'>   <Link target='_blank' href="https://www.g2.com/products/konfhub/reviews" data-sentry-element="Link" data-sentry-source-file="HeroSection.jsx"> <button className="gartner-btn fw-bold font-sm"> Review us on G2 </button></Link>
            </div>
          </section>
          {/* <section className=' m-auto text-center py-4'>
            <GartnerReviews />
           </section> */}
        </div>
      </div>

      <style jsx>
        {`
          .main-container {
            width: 100%;
          }
          .banner-video{
            //box-shadow: 0px 8px 8px -4px #10182808;
            //box-shadow: 0px 20px 24px -4px #10182814;
            //border:8px solid #002E6E;
           // border-radius:8px;
           
          }
          video{
              max-height:505px !important;
              height:100% !important;
              //height:505px !important;
            }

          .hero-section-container {
            background: url('/img/abstract-bg.webp') no-repeat;
            width: 100%;
            height: 100%;
            min-height: 500px;
            background-size: cover;
          }

          .cta-img {
            max-width: 100%;
            height: auto;
          }

          .ratings-section {
            max-width: 700px;
            width: 100%;
          }

          .hero-section h1 {
            line-height: 72px;
            letter-spacing: 0.5px;
          }

          .hero-section p {
            line-height: 24px;
          }

          .host-btn {
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            font-weight: 700;
            background-color: ${websiteColors[theme].primaryWebsiteTheme};
            color: #fff;
            padding: 5px 40px;
            border-radius: 4px;
          }
          .gartner-btn {
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            font-weight: 700;
            background-color: ${websiteColors[theme].primaryWebsiteTheme};
            color: #fff;
            padding: 5px 10px;
            border-radius: 4px;
          }

          .demo-btn {
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            font-weight: 700;
            color: ${websiteColors[theme].primaryWebsiteTheme};
            padding: 5px 40px;
            border-radius: 4px;
            border: 1px solid ${websiteColors[theme].primaryWebsiteTheme};
            background-color: #fff;
          }

          @media screen and (max-width: 768px) {
            .hero-section-title {
              font-size: 48px;
              font-weight: 600;
              line-height: 72px;
            }
          }

          @media screen and (max-width: 576px) {
            .hero-section-title {
              font-size: 48px !important;
              font-weight: 600;
              line-height: 55px !important;
            }
            .rating-hero-cont{
              gap:0px !important;
            }

            .hero-section-desc {
              font-size: 20px !important;
              font-weight: 400;
              line-height: 30px;
            }

            .host-btn,
            .demo-btn {
              width: 100%;
            }
          }

          @media screen and (max-width: 380px) {
            .hero-section-title {
              font-size: 40px !important;
              letter-spacing: 0px;
              line-height: 18px;
            }
          @media screen and (max-width: 371px) {
            .hero-section-title {
              font-size: 36px !important;
              letter-spacing: 0px;
              line-height: 18px;
            }
          }
        `}
      </style>
    </div>;
};
export default HeroSection;